<template>
    <section class="deposit">
        <div class="top-bar bg-white" style="justify-content: space-between;align-items: center">
            <div>
                时<span style="padding: 10px"/>间 :<span style="padding: 10px"/>
                <el-select v-model="year" placeholder="请选择" size="small">
                    <el-option v-for="(item,index) in 100" :key="index" :label="index+2000" :value="index+2000"/>
                </el-select>
<!--                <span style="padding: 10px">住房类型 :</span>-->
<!--                <el-select v-model="parent" placeholder="请选择" size="small">-->
<!--                    <el-option label="全部" :value="0"/>-->
<!--                    <el-option label="保障性租赁住房" :value="600210"/>-->
<!--                    <el-option label="长租公寓" :value="600220"/>-->
<!--                </el-select>-->
<!--                <span style="padding: 5px"/>-->
<!--                <el-select v-model="ziji"  placeholder="请选择" size="small">-->
<!--                    <el-option v-loading="loading" label="全部" :value="0"/>-->
<!--                    <el-option v-for="(item,index) in houseTypes" :key="index" :label="item.codeName" :value="item.code"/>-->
<!--                </el-select>-->
            </div>
            <div>
                <el-button type="primary" size="small" @click="handleSearch">筛选</el-button>
                <!-- 2024/02/05 张晓瑜注释 导出 跟查看已导出列表 -->
                <!-- <el-button size="small" @click="earnestExport">导出</el-button>
                <span style="padding-left: 30px;font-size: 18px;">
                    <el-link type="primary" :underline="false" style="color:#2C8BF7!important;">查看已导出列表</el-link>
                </span> -->
                <span style="padding: 15px;font-size: 18px;">
                    <el-link type="primary" :underline="false" style="color:#2C8BF7!important;" @click="handleReset">重置筛选条件</el-link>
                </span>
            </div>
        </div>
        <div style="display:flex;justify-content: space-between;">
            <div style="width: 49%;">
                <div>
                    <div class="title">押金走势图</div>
                </div>
                <div style="margin-bottom: 30px;background-color: #FFF;border-radius: 10px;padding: 20px;">
                    <div id="depositECharts" style="height: 300px;"></div>
                </div>
            </div>
            <div style="width: 49%;">
                <div>
                    <div class="title">押金支付占比</div>
                </div>
                <div style="margin-bottom: 30px;background-color: #FFF;border-radius: 10px;padding: 20px;display: flex">
                    <div id="paymentECharts" style="height: 300px;width: 55%"></div>
                    <div style="height: 300px;width: 45%">
                        <div class="operation-item slider" style="width: 100%">
                            <div class="slider-item flex align-center" v-for="item in operationSlider" :key="item.name">
                                <div class="label">{{ item.label }}</div>
                                <el-slider :class="item.name" :value="item.rate" style="width: 80%" disabled></el-slider>
                                <div class="rate">{{ item.rate }}%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="padding: 25px;background-color: #ffffff;border-radius: 10px">
            <r-e-table class="bg-white" ref="depositTableRef" :dataRequest="getBillEarnestList" show-summary
                       :columns="depositTableColumn" :query="formSearch" :height="300" :summary-method="getSummaries">
                <template slot="empty">
                    <el-empty/>
                </template>
            </r-e-table>
        </div>
    </section>
</template>

<script>
    import * as echarts from "echarts";
    import {depositTableColumn, eChartsOptions, paymentEChartsOptions} from "@/views/bill-management/rent-withdrawal/data";
    import {getBillEarnestList,getBillEarnestLine,getBillEarnestSector,earnestExport} from "@/api/rent-withdrawal";
    import {downloadByData} from "@/utils/hooks/download";
    import { timeFormat, numberFormat } from "@custom/index";
    export default {
        name: "deposit",
        components: {},
        data() {
            return {
                formSearch: {},
                year: (new Date()).getFullYear(),
                depositECharts: null,
                paymentECharts: null,
                eChartsOptions: eChartsOptions,
                paymentEChartsOptions: paymentEChartsOptions,
                depositTableColumn,
                operationSlider: [
                    {
                        label: "微信",
                        name: "a1",
                        rate: 30
                    },
                    {
                        label: "支付宝",
                        name: "a2",
                        rate: 20
                    },
                    {
                        label: "转账",
                        name: "a3",
                        rate: 10
                    },
                    {
                        label: "现金",
                        name: "a4",
                        rate: 40
                    },
                    {
                        label: "刷卡",
                        name: "a5",
                        rate: 60
                    },],
                parent: 0,
                ziji: 0,
                houseTypes: [],
                loading: false
            };
        },
        methods: {
            getBillEarnestList(params){
                let year = this.year;
                if (year === null || year === "") year = (new Date()).getFullYear();
                let code = this.ziji === 0 ? this.parent : this.ziji;
                params.year = year;
                params.code = code;
                return getBillEarnestList(params);
            },
            getBillEarnestLine(){
                let year = this.year;
                if (year === null || year === "") year = (new Date()).getFullYear();
                let code = this.ziji === 0 ? this.parent : this.ziji;
                getBillEarnestLine({year,code}).then(res=>{
                    let {xList, yList} = res.info;
                    this.setOptionByLine("depositECharts",xList, yList);
                }).catch(err=>{});
            },

            getBillEarnestSector(){
                let year = this.year;
                if (year === null || year === "") year = (new Date()).getFullYear();
                let code = this.ziji === 0 ? this.parent : this.ziji;
                getBillEarnestSector({year,code}).then(res=>{
                    let {list} = res;
                    this.setOptionByPancake("paymentECharts",list);

                }).catch(err=>{});
            },
            earnestExport(){
                const loading = this.$loading({lock: true, text: "正在导出请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                let year = this.year;
                if (year === null || year === "") year = (new Date()).getFullYear();
                let code = this.ziji === 0 ? this.parent : this.ziji;
                earnestExport({year,code}).then(res=>{
                    downloadByData({data: res, filename: `押金报表${timeFormat(new Date(),'yyyy-MM-dd-HH-mm-ss')}.xls`});
                    loading.close();
                }).catch(err => loading.close());
            },
            eChartsInit(eChartsName,eChartsOptions) {
                let {...options} = eChartsOptions;
                this[eChartsName] = echarts.init(document.getElementById(eChartsName));
                this[eChartsName].setOption(options, true);
                window.addEventListener("resize", () => {
                    this[eChartsName].resize();
                });
            },
            handleSearch(){
                this.$refs["depositTableRef"].pNumber = 1;
                this.$refs["depositTableRef"].getTableData();
                this.getBillEarnestLine();
              this.getBillEarnestSector();
            },
            handleReset() {
                this.formSearch = {page: 1, pageSize: 10};
                this.parent = 0;
                this.ziji = 0;
                this.year = (new Date()).getFullYear();
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            setOptionByLine(eChartsName,xList,yList){
                let {...eChartsOptions} = this.eChartsOptions;
                // 获取首个 ‘折线’ 数据，并将按照首个‘折线’数据的配置,装载‘折线’数据
                let legendData = {icon: 'roundRect', textStyle: {fontSize:15}};
                let legendDataArr = [];
                for (let item of yList) {
                    let {...data} = legendData;
                    data.name = item.name;
                    legendDataArr.push(data);
                }
                //填充‘折线’数据 主要是对样式的配置
                eChartsOptions.legend = {data: legendDataArr};
                //eCharts x轴配置
                eChartsOptions.xAxis.data = xList;
                //获取首个数据，并将按照首个数据的配置,装载数据
                let seriesData = {type: 'line',smooth:true};
                let seriesDataArr=[];
                for(let item of yList){
                    let {...data}=seriesData;
                    data.name = item.name;
                    data.data = item.data.map(a => a /= 100);
                    seriesDataArr.push(data);
                }
                //eCharts 填充数据
                eChartsOptions.series = seriesDataArr;
                this[eChartsName].setOption(eChartsOptions, true);
                //this.eChartsInit(eChartsName,eChartsOptions);
            },

            setOptionByPancake(eChartsName,list){
                let {...paymentEChartsOptions} = this.paymentEChartsOptions;
                let [...operationSlider]=this.operationSlider;
                let [...data]= paymentEChartsOptions.series[0].data;
                for(let item of data){
                    item.value=list.find(o=>o.name===item.name).value;
                }
                for(let item of operationSlider){
                    item.rate=list.find(o=>o.name===item.label).percent;
                }
                this.operationSlider=operationSlider;
                this[eChartsName].setOption(paymentEChartsOptions, true);
            },

            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if (index === 2 || index === 3 || index === 4 || index === 5 || index === 6) {
                        sums[index] = '';
                        return;
                    }
                    const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) return prev + curr;
                            else return prev;
                        }, 0);
                        sums[index] = numberFormat(sums[index] / 100);
                    } else sums[index] = '';
                });
                return sums;
            }
        },
        async mounted() {
         await this.getBillEarnestSector();
            this.eChartsInit("depositECharts", this.eChartsOptions);
            this.getBillEarnestLine();
            this.eChartsInit("paymentECharts", this.paymentEChartsOptions);
        },
        beforeDestroy() {
            window.removeEventListener("resize", () => {
                this.thisYearECharts.resize();
                this.oldDebtECharts.resize();
            });
        },
        watch: {
            async parent(value) {
                if (value !== 0) {
                    this.ziji = 0;
                    this.loading = true;
                    this.houseTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', value);
                    this.loading = false;
                } else {
                    this.ziji = 0;
                    this.houseTypes = [];
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .deposit {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(10px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                //white-space: nowrap;
                text-align: center;
            }
        }

        .title {
            height: VH(50px);
            line-height: VH(50px);
            color: #666;
            padding-left: VW(10px);
            position: relative;

            &::before {
                width: 5px;
                height: 40%;
                background-color: #5C84FB;
                content: '';
                position: absolute;
                left: 0;
                top: 30%;
            }
        }

        .operation-item {
            height: 100%;
        }
        .slider {
            width: 53%;
            padding: VH(20px) 0 0 VW(20px);
            border-left: 1px dashed #D7D7D7;

            .slider-item {
                padding-bottom: VH(10px);

                .label {
                    width: VW(120px);
                    color: #33558B;
                    font-weight: 600;
                    font-size: rem(18px);
                }

                .value {
                    width: VW(80px);
                    padding: 0 VW(15px);
                    word-break: break-all
                }

                .rate {
                    width: VW(50px);
                }

                /deep/ .el-slider {
                    width: VW(426px);

                    .el-slider__runway, .el-slider__bar {
                        height: VH(13px);
                        border-radius: 9px;
                    }

                    .el-slider__button-wrapper {
                        display: none;
                    }

                    &.a1 .el-slider__bar {
                        background-color: #5087EC;
                    }

                    &.a2 .el-slider__bar {
                        background-color: #68BBC4;
                    }

                    &.a3 .el-slider__bar {
                        background-color: #58A55C;
                    }

                    &.a4 .el-slider__bar {
                        background-color: #F2BD42;
                    }

                    &.a5 .el-slider__bar {
                        background-color: #EE752F;
                    }
                }
            }
        }
    }
</style>
